import React from "react";
import styled from "styled-components";
import tw from "tailwind.macro";

const MinecraftContainer = styled.div`
  ${tw`w-3/5 min-w-3/5 m-auto bg-local cursor-pointer bg-orange-900 pl-12 pr-12 pt-4 pb-4 border-2 border-solid border-gray-800 mt-3 mb-3 rounded-lg`}
  background-image: url(${ require("../../assets/background.jpg") });
`;

const ServerAddressLabel = styled.p`
  ${tw`font-minecraft text-lg`}
  color: #aaa;
`;

const AddressBar = styled.p`
  ${tw`font-minecraft text-xl text-white bg-black p-2 border-2 border-solid mt-2`}
  border-color: #9F9F9F;
`

const Validate = styled.p`
  ${tw`font-minecraft text-lg text-white max-w-xs m-auto mt-4 mb-2 text-center pt-2 pb-2 bg-gray-300 border-1 border-solid border-black`}
    background-image: url(${ require("../../assets/validateBackground.jpg") });
    background-size: cover;
`

class MinecraftConnectionSimulator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addressBarText: 'craftasia.fr' };
  }

  componentDidMount() {
    this._BlinkUnderscore('addressBarText')
  }

  _BlinkUnderscore = stateKey => {
    setInterval(() => {
      this.setState({
        [stateKey]: this.state[stateKey] + '_'
      });

      setTimeout(() => {
        this.setState({
          [stateKey]: this.state[stateKey].slice(0, -1)
        });
      }, 700);
    }, 1400);
  }

  render() {
    return (
      <MinecraftContainer>
        <ServerAddressLabel>Adresse du serveur</ServerAddressLabel>
        <AddressBar>{this.state.addressBarText}</AddressBar>
        <Validate>Valider</Validate>
      </MinecraftContainer>
    )
  }
}

export default MinecraftConnectionSimulator;
