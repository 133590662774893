import React from "react";
import styled from "styled-components";
import tw from "tailwind.macro";

const ButtonContainer = styled.div`
  ${tw`flex justify-center mt-8 cursor-pointer`}
`;

const Button = styled.a`
  ${tw`border-2 border-solid border-craftasiaBlack rounded-lg p-3 hover:bg-craftasiaBlack hover:text-white`}
    transition: 0.15s;
`;


const ExternalButton = () => {
  return (
    <ButtonContainer>
      <Button href="https://craftasia.fr/rejoindre" target="_blank">
        Visiter le site Craftasia
      </Button>
    </ButtonContainer>
  )
}

export default ExternalButton;
